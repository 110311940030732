<template>
  <div class="StatusPageList">
    <header class="header container-fluid">
      <div class="row">
        <div class="col col-md-3 status-title">Title</div>
        <div class="col col-md-4 status-title monitor-title">Monitors</div>
        <div class="col col-md-4 status-title">Public Link</div>
        <div class="col col-md-1 status-title"></div>
      </div>
    </header>

    <item-placeholder-list v-if="loading" />

    <draggable v-model="statusPages" ghost-class="ghost" @change="onChange" animation="200">
      <transition-group type="transition" name="flip-list">
        <div :id="statusPage.id" v-for="statusPage in statusPages" :key="statusPage.id" class="sortable d-flex">
          <img :src="require('@/assets/images/reorder-indicator.svg')" alt="" class="reorder-indicator"/>
          <status-page-item
            :key="statusPage.id"
            :status-page="statusPage"
            @delete="loadStatusPages"
          />
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import ItemPlaceholderList from '@/components/Common/ItemPlaceholderList.vue'
import StatusPageItem from './StatusPageListItem/StatusPageItem.vue'

import statusPagesApi from '@/api/statusPagesApi.js'
import draggable from 'vuedraggable'

export default {
  components: {
    ItemPlaceholderList,
    StatusPageItem,
    draggable
  },

  data () {
    return {
      statusPages: [],
      loading: false
    }
  },

  created () {
    this.loadStatusPages()
  },

  methods: {
    // TODO: Is it possible to refactor this pattern?
    async loadStatusPages () {
      this.loading = true
      this.statusPages = []
      this.statusPages = await statusPagesApi.get()
      this.loading = false
    },
    onChange: async function (event) {
      const data = {
        id: event.moved.element.id,
        oldPosition: event.moved.oldIndex,
        newPosition: event.moved.newIndex
      }
      try {
        this.arranging = true
        await statusPagesApi.arrangeStatusPages(data)
      } catch (error) {
        console.error(error)
      }
      this.arranging = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .StatusPageList {
    .header {
      margin-bottom: 10px;
      color: #999ca7;
    }
    .status-title {
      white-space: nowrap;
      width: 30px;
    }
    .reorder-indicator {
      padding-bottom: 16px;
      opacity: 0;
      cursor: move;
      position: relative;
      left: -25px;
      margin-right: -11px;
    }
    .sortable:hover > .reorder-indicator {
      opacity: 1;
    }

    @media (max-width: 1080px) {
      .monitor-title { 
        display: none;
      }
    }
  }
</style>
